const redirects = {
   'url1' : { landing_page : 'https://sos-barnbyar.se/foretag/', name: "Landningssida företag" },
   'url2' : { landing_page : 'https://sos-barnbyar.se/julgava-foretag-1/', name: "Julgåva 1" },
   'url3' : { landing_page : 'https://sos-barnbyar.se/julgava-foretag-2/', name: "Julgåva 2" },
   'url4' : { landing_page : 'https://sos-barnbyar.se/julgava-foretag-3/', name: "Julgåva 3" },
   'url5' : { landing_page : 'https://sos-barnbyar.se/barn-i-sudan-kanner-sig-overgivna-av-omvarlden/', name: "Case: Malik" },

   'url6' : { landing_page : 'https://sos-barnbyar.se/julgava-foretag-1/', name: "Julgåva 1" },
   'url7' : { landing_page : 'https://sos-barnbyar.se/poesin-gav-nour-livet-tillbaka/', name: "Case: Nour" },

};

export default redirects